/* scale is set with multiples of 4 */

.container {
  height: 100vh;
  position: relative;
  max-width: 1080px;
  margin: 0 auto;
  /* 6 + 6 */
  padding: 6px;
}

.credits {
  position: fixed;
  bottom: 24px;
  right: 24px;
}

.heading {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 40px 0;
  font-size: 3.6rem;
  font-weight: 300;
  font-style: italic;
}

@media (max-width: 814px) {
  .heading {
    flex-flow: column wrap;
  }
}
